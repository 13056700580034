import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import locale_en from 'react-intl/locale-data/en'
import locale_es from 'react-intl/locale-data/es'
import messages_en from 'i18n/en'
import messages_es from 'i18n/es'
import { returnRightCurrency, returnRightCurrencyKey } from 'components'
import { PropTypes } from 'prop-types'

addLocaleData([...locale_en, ...locale_es])

export const UserContext = React.createContext()

class UserProvider extends React.Component {
  constructor(props) {
    super(props)
    this.defaultState = {
      action: '/apiv2/user/',
      balanceAction: '/apiv2/user/wallet/balance/',
      error: null,
      data: [],
      balanceData: [],
      userToken: null,
      email: null,
      username: null,
      twofa: null,
      twofaQr: null,
      twofaToken: null,
      walletUid: null,
      language: 'en',
      currency: 'usd',
      balance: null,
      avatarUID: null,
      bannerUID: null,
      fees: {
        slx: {
          cost: {
            int: 100,
            dispo: 6,
            pos: 4,
            display: 0.0001,
          },
          size: '1 kb',
        },
      },
      messages: messages_en,
    }
    this.state = {
      ...this.defaultState,
      setState: this.setState.bind(this),
      updateUser: this.updateUser.bind(this),
      // sendUser: this.sendUser.bind(this),
      clearAll: this.clearAll.bind(this),
      returnTempState: this.returnTempState.bind(this),
      crunchCurrency: this.crunchCurrency.bind(this),
      crunchCurrencyKey: this.crunchCurrencyKey.bind(this),
      setInterval: this.setInterval.bind(this),
      removeInterval: this.removeInterval.bind(this),
    }
    this.userState = {
      userToken: null,
    }
    const rawUserState = window.sessionStorage.getItem('userState')
    if (rawUserState) {
      this.userState = JSON.parse(rawUserState)
    }
    this.interval = null
  }

  componentDidMount() {
    this.updateUser()
  }

  componentDidUpdate() {
    window.sessionStorage.setItem('userState', JSON.stringify(this.state))
  }

  componentWillUnmount() {
    this.removeInterval()
  }

  setInterval() {
    const { userToken: userTokenState } = this.state
    const userToken = userTokenState ? userTokenState : this.userState.userToken
    if (userToken) {
      this.interval = setInterval(() => {
        this.updateUser()
      }, 15 * 1000)
    }
  }

  setMsg() {
    let messages = null
    const { language } = this.state
    switch (language) {
      case 'en':
        messages = messages_en
        break
      case 'es':
        messages = messages_es
        break
      default:
        messages = messages_en
        break
    }
    this.setState({ messages })
  }

  // sendUser() {
  //   const { userToken, action, language, currency, twofa } = this.state
  //   const url = new URL(
  //     `${window.location.protocol}//${window.location.host}${action}`
  //   )
  //   const user_uid = userToken

  //   window
  //     .fetch(url, {
  //       method: 'POST',
  //       credentials: 'include',
  //       body: JSON.stringify({
  //         // token_uid, // TODO: (PB) find out where this is being set
  //         user_uid,
  //         language: language,
  //         currency: currency,
  //         twofa: twofa,
  //       }),
  //     })
  //     .then((response) => {
  //       if (response.status === 304) {
  //         return true
  //       } else if (response.status !== 200) {
  //         console.error(url, 'Fetch POST did not return 200 or 304')
  //         return false
  //       }
  //       return response.json()
  //     })
  //     .then((data) => {
  //       if (data.state) {
  //         const newState = { data: data.data }

  //         // console.log('>> user Context', data.data)

  //         if (
  //           data.data.twofa &&
  //           data.data.twofa.qrcode &&
  //           data.data.twofa.token
  //         ) {
  //           newState.twofaQr = data.data.twofa.qrcode
  //           newState.twofaToken = data.data.twofa.token
  //         } else {
  //           newState.twofaQr = null
  //           newState.twofaToken = null
  //         }
  //         this.setState(newState)
  //       } else {
  //         console.error(url, 'POST Data state not True')
  //         return false
  //       }
  //     })
  //     .then(() => {
  //       this.updateUser()
  //     })
  // }

  clearAll() {
    window.sessionStorage.removeItem('userState')
    this.removeInterval()
    this.setState(this.defaultState, () => this.setMsg())
  }

  returnTempState() {
    return this.userState
  }

  removeInterval() {
    clearInterval(this.interval)
  }

  crunchCurrency(data) {
    const { currency } = this.state
    return returnRightCurrency(data, currency)
  }

  crunchCurrencyKey(data) {
    const { currency } = this.state
    return returnRightCurrencyKey(data, currency)
  }

  fetchUserData(endpoint) {
    console.log('user token api', endpoint)
    window
      .fetch(endpoint, { credentials: 'include' })
      .then((response) => {
        if (response.status !== 200) {
          console.error(endpoint, 'Fetch did not return 200')
          return false
        }
        return response.json()
      })
      .then((data) => {
        if (data.state) {
          // console.log(' update user', data.data)
          this.setState(
            {
              data: data.data,
              email: data.data.user.email,
              username: data.data.user.username,
              language: data.data.user.language,
              currency: data.data.user.currency,
              twofa: data.data.user.twofa,
            },
            () => this.setMsg()
          )
        } else {
          console.error(endpoint, 'Data state not True')
          return false
        }
      })
  }

  // fetchAvatars() {
  //   const endpoint = '/apiv2/user/avatars/'
  //   const { avatarUID } = this.state

  //   window
  //     .fetch(endpoint, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //       },
  //     })
  //     .then(response => {
  //       console.log('response', response)
  //       if (response.status !== 200) {
  //         console.error(endpoint, 'Fetch did not return 200')
  //         return false
  //       }
  //       return response.json()
  //     })
  //     .then(data => {
  //       if (data.state) {
  //         console.log(' avatar data', data.data)
  //         const avatarItem = data.data.avatar.find(
  //           item => item['assetmap_uid'] === avatarUID
  //         )
  //         this.setState(
  //           {
  //             avatarURL: avatarItem.url,
  //           },
  //           () => this.setMsg()
  //         )
  //       } else {
  //         console.error(endpoint, 'Data state not True')
  //         return false
  //       }
  //     })
  // }

  // fetchBanners() {
  //   const endpoint = '/apiv2/user/banners/'
  //   const { bannerUID } = this.state

  //   window
  //     .fetch(endpoint, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //       },
  //     })
  //     .then(response => {
  //       if (response.status !== 200) {
  //         console.error(endpoint, 'Fetch did not return 200')
  //         return false
  //       }
  //       return response.json()
  //     })
  //     .then(data => {
  //       if (data.state) {
  //         console.log(' banner data', data.data)
  //         const bannerItem = data.data.banner.find(
  //           item => item['assetmap_uid'] === bannerUID
  //         )
  //         this.setState(
  //           {
  //             bannerURL: bannerItem.url,
  //           },
  //           () => this.setMsg()
  //         )
  //       } else {
  //         console.error(endpoint, 'Data state not True')
  //         return false
  //       }
  //     })
  // }

  fetchProfileData(endpoint) {
    window
      .fetch(endpoint, { credentials: 'include' })
      .then((response) => {
        if (response.status !== 200) {
          console.error(endpoint, 'Fetch did not return 200')
          return false
        }
        return response.json()
      })
      .then((data) => {
        if (data.state) {
          // console.log(' profile data', data.data)
          this.setState(
            {
              avatarUID: data.data.profile['avatar_uid'],
              bannerUID: data.data.profile['banner_uid'],
            },
            () => this.setMsg()
          )
        } else {
          console.error(endpoint, 'Data state not True')
          return false
        }
      })
  }

  updateUser() {
    console.log('update user called')
    /* console.log("inside user updateUser")
    console.log(this.state) */
    const { userToken: userTokenState, action } = this.state
    const userToken = userTokenState
      ? userTokenState
      : this.userState.userToken
    this.setState({ userToken }, () => {
      const url = new URL(
        `${window.location.protocol}//${window.location.host}${action}`
      )
      const params = { user_uid: userToken }
      url.search = new URLSearchParams(params)
      if (userToken) {
        this.fetchUserData(url)
        this.fetchProfileData('/apiv2/user/profile/')
        // this.fetchBanners()
        // this.fetchAvatars()
      }
      if (!userTokenState) {
        console.error('updateUser No user token so no fetch.')
        return false
      }
    })
  }

  render() {
    const { language, messages } = this.state
    const { children } = this.props
    return (
      <UserContext.Provider value={this.state}>
        <IntlProvider
          key={language}
          locale={language}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </UserContext.Provider>
    )
  }
}

UserProvider.defaultProps = {
  children: null
}

UserProvider.propTypes = {
  children: PropTypes.node
}

export default UserProvider
