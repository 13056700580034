// # IMPORT APPLICATION CONSTANTS
// import * as constant from 'constants'
import {
  USER_GET_STARTED,
  USER_GET_SUCCESS,
  USER_GET_FAILED,
  USER_SET_TOKEN,
  USER_UPDATE_PROFILE_IMAGES,
  USER_SIGN_IN,
  USER_SIGN_OUT,
  USER_UPDATE_SETTINGS_STARTED,
  USER_UPDATE_SETTINGS_SUCCESS,
  USER_UPDATE_SETTINGS_FAILED,
  USER_UPDATE_PASSWORD_STARTED,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAILED,
} from 'constants'

const initialState = {
  data: null,
  userToken: null,
  loaded: false,
}

export default function user(state = initialState, action) {
  switch (action.type) {

    case USER_GET_STARTED:
      return {
        ...state
      }

    case USER_GET_SUCCESS:
      return {
        ...state,
        loaded: true,
        data: action.payload
      }
    case USER_GET_FAILED:
      return {
        ...state,
        loaded: false
      }

    case USER_SET_TOKEN:
      return {
        ...state,
        userToken: action.payload,
      }

    case USER_UPDATE_PROFILE_IMAGES:
      return {
        ...state,
      }
      
    case USER_SIGN_IN:
      return {
        ...state,
        userToken: action.payload,
      }
      
    case USER_SIGN_OUT:
      return {
        data: null,
        userToken: null,
        loaded: false,
      }
      
    case USER_UPDATE_SETTINGS_STARTED:
      return {
        ...state
      }
  
    case USER_UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
      }

    case USER_UPDATE_SETTINGS_FAILED:
      return {
        ...state,
      }

    case USER_UPDATE_PASSWORD_STARTED:
      return {
        ...state
      }
  
    case USER_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
      }

    case USER_UPDATE_PASSWORD_FAILED:
      return {
        ...state,
      }

    default:
      return state

  }
}
