import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { PropTypes } from 'prop-types'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      window.sessionStorage.getItem('userToken') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/sign-in', state: { from: props.location } }}
        />
      )
    }
  />
)

PrivateRoute.defaultProps = {
  location: null,
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
}
export default PrivateRoute
