import React from 'react'
import {
  Grid,
  Typography,
  Hidden,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from 'Styles'
import { PropTypes } from 'prop-types'
import SlateFooterIcon from 'assets/icons/Logos/slate-play-ico-grey.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDiscord,
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faRedditAlien,
} from '@fortawesome/free-brands-svg-icons'

// import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
// import { Newsletter } from 'components'
import { useSelector } from 'react-redux'

const Footer = (props) => {
  const { classes, location } = props
  const userData = useSelector((state) => state.user)

  const copyrightEl = (
    <div
      className={classes.footerCopyright}
      style={{ display: 'inline-block' }}
    >
      &copy; 2019 SlatePlay Corporation 
      {' '}
      <br />
      All rights reserved.
    </div>
  )

  const slateIcon = <SlateFooterIcon className={classes.footerLogo} />
  let showFooter = true

  if (location.pathname === '/' && userData.userToken) {
    showFooter = false
  }

  return (
    <>
      {showFooter && (
        <Grid
          container
          className={classes.footerBackground}
        >
          <Grid
            container
            className={classes.footerContainer}
            direction='row'
            spacing={2}
          >
            <Grid
              container
              item
              sm={12}
              spacing={2}
            >
              <Grid
                item
                xs={6}
                sm={3}
                className={classes.footerGroup}
              >
                <Typography className={classes.footerTitle}>
                  Entertainment
                </Typography>

                <div className={classes.footerKeyline} />

                <ul className={classes.footerList}>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/esports'
                    >
                      eSports
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/channels'
                    >
                      Channels
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/streaming'
                    >
                      Streaming
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/news'
                    >
                      Latest
                    </Link>
                  </li>
                </ul>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                className={classes.footerGroup}
              >
                <Typography className={classes.footerTitle}>Rewards</Typography>

                <div className={classes.footerKeyline} />

                <ul className={classes.footerList}>
                  <li className={classes.footerListItem}>
                    {/* <Link
                      className={classes.footerItem}
                      to='/about'
                    > */}
                    SLX Rewards
                    {/* </Link> */}
                  </li>
                  <li className={classes.footerListItem}>
                    {/* <Link
                      className={classes.footerItem}
                      to='/partner'
                    > */}
                    Wallet
                    {/* </Link> */}
                  </li>
                  <li className={classes.footerListItem}>
                    {/* <Link
                      className={classes.footerItem}
                      to='/contact'
                    > */}
                    Store
                    {/* </Link> */}
                  </li>
                  <li className={classes.footerListItem}>
                    {/* <Link
                      className={classes.footerItem}
                      to={{ pathname: '/faq' }}
                    > */}
                    Tech
                    {/* </Link> */}
                  </li>
                </ul>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                className={classes.footerGroup}
              >
                <Typography className={classes.footerTitle}>Company</Typography>

                <div className={classes.footerKeyline} />

                <ul className={classes.footerList}>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/about'
                    >
                      About
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/partner'
                    >
                      Become A Partner
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/contact'
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to={{ pathname: '/faq' }}
                    >
                      FAQ
                    </Link>
                  </li>
                </ul>
              </Grid>

              <Grid
                item
                xs={6}
                sm={3}
                className={classes.footerGroup}
              >
                <Typography className={classes.footerTitle}>Legal</Typography>

                <div className={classes.footerKeyline} />

                <ul className={classes.footerList}>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/privacypolicy'
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className={classes.footerListItem}>
                    <Link
                      className={classes.footerItem}
                      to='/terms'
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </Grid>

              {/* spacer */}
              {/* <Hidden only={['xs', 'sm']}>
                  <Grid
                    item
                    xs={2}
                    sm={1}
                  />
                </Hidden> */}

              {/* copyright */}
              <Hidden xsDown>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  justify='flex-start'
                  className={classes.footerCopyWrap}
                >
                  <Typography
                    className={classes.footerListItem}
                    style={{ display: 'inline-block' }}
                    component='div'
                  >
                    {slateIcon} 
                    {' '}
                    {copyrightEl}
                  </Typography>
                </Grid>
              </Hidden>

              {/* social icons */}
              <Grid
                item
                xs={12}
                sm={6}
                container
                justify='flex-end'
                className={classes.footerGroup}
              >
                {/* <Typography className={classes.footerTitle}>
                  Stay Connected
                </Typography>

                <div className={classes.footerKeyline} /> */}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.footerStayConnected}
                  container
                  justify='space-between'
                >
                  <Grid
                    item
                    xs={2}
                  >
                    <Typography className={classes.footerIconContainer}>
                      {/* <a
                        href='https://discord.gg/ENuwHH5'
                        target='_blank'
                        href='#'
                      > */}
                      <FontAwesomeIcon
                        icon={faDiscord}
                        size='lg'
                        className={classes.footerSocialIcon}
                      />
                      {/* </a> */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                  >
                    <Typography className={classes.footerIconContainer}>
                      {/* <a
                        href='https://twitter.com/slatecurrency'
                        target='_blank'
                        href='#'
                      > */}
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size='lg'
                        className={classes.footerSocialIcon}
                      />
                      {/* </a> */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                  >
                    <Typography className={classes.footerIconContainer}>
                      {/* <a
                        href='https://www.facebook.com/slatecurrency'
                        target='_blank'
                        href='#'
                      > */}
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        size='lg'
                        className={classes.footerSocialIcon}
                      />
                      {/* </a> */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                  >
                    <Typography className={classes.footerIconContainer}>
                      {/* <a
                        href='https://www.instagram.com/slatecurrency/'
                        target='_blank'
                        href='#'
                      > */}
                      <FontAwesomeIcon
                        icon={faInstagram}
                        size='lg'
                        className={classes.footerSocialIcon}
                      />
                      {/* </a> */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                  >
                    <Typography className={classes.footerIconContainer}>
                      {/* <a
                        // href='https://www.youtube.com/channel/UCvfUCFJyiUnTJ7hmkngNjbQ'
                        // target='_blank'
                        href='#'
                      > */}
                      <FontAwesomeIcon
                        icon={faYoutube}
                        size='lg'
                        className={classes.footerSocialIcon}
                      />
                      {/* </a> */}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                  >
                    <Typography className={classes.footerIconContainer}>
                      {/* <a
                        // href='https://www.reddit.com/r/Slatecurrency/'
                        // target='_blank'
                        href='#'
                      > */}
                      <FontAwesomeIcon
                        icon={faRedditAlien}
                        size='lg'
                        className={classes.footerSocialIcon}
                      />
                      {/* </a> */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  justify='flex-start'
                  className={classes.footerCopyWrap}
                >
                  <Typography
                    className={classes.footerListItem}
                    component='div'
                  >
                    {slateIcon} 
                    {' '}
                    {copyrightEl}
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

Footer.defaultProps = {
  classes: {},
  location: {},
}

Footer.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(withStyles(styles)(Footer))
