// import { relative } from 'path'

const styles = (theme) => ({
  errorText: {
    color: theme.palette.error.main,
  },
  successText: {
    color: '#417115',
  },
  maxWidth: {
    width: '100%',
    maxWidth: 1920,
  },
  presentationWrapper: {
    width: '100%',
    maxWidth: 1920,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mrgnRight: {
    marginRight: theme.spacing(2),
  },
  innerStdPadded: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  carouselPadded: {
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.between('sm', 'xl')]: {
      paddingLeft: 36,
      paddingRight: 36,
    },
  },
  caroButtonsMob: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.875rem',
    },
  },
  paddedHor: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paddedVert: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddedTopNone: {
    paddingTop: 0,
  },
  
  mainContent: {
    flex: 2,
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: 50,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: 75,
    },
  },
  bodyTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 50,
    color: '#FFF',
    '& h1': {
      color: '#FFF',
    },
    [theme.breakpoints.between('md', 'xl')]: {
      minHeight: 75,
    },
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 101,
    backgroundColor: '#11161d',
    color: theme.palette.primary.dark,
  },
  menu: {
    color: '#18ffbd',
    height: theme.spacing(3),
    width: theme.spacing(3),
    'g#Group': {
      fill: '#18ffbd !important',
    },
  },
  appbarLogoWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${theme.spacing(6)}px 0 0 !important`,
    [theme.breakpoints.only('xs')]: {
      margin: '0 auto 0 0 !important',
    },
  },
  appbarLogoBtn: {
    padding: 0,
    '& img': {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  slateLogo: {
    width: '225px !important',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '157.4px !important',
    },
    [theme.breakpoints.only('xs')]: {
      height: '27px !important',
      width: '130px !important',
    },
  },
  headerDeskLink: {
    color: '#CECECE',
    fontWeight: 600,
    padding: `0 ${theme.spacing(5)}px`,
    fontSize: '1rem',
  },
  drawerSpacer: {
    marginTop: 75,
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: 75,
    },
  },
  drawerPaper: {
    backgroundColor: '#13161D',
    boxShadow: 'none',
  },
  drawerText: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: '1rem !important',
  },
  textGreen: {
    color: '#82d8bf',
  },
  drawerHeading: {
    color: theme.palette.secondary.main,
  },
  drawerDivider: {
    margin: theme.spacing(1, 4),
    height: 1,
    backgroundColor: 'rgba(255,255,255,0.1)',

    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  drawerList: {
    padding: theme.spacing(2, 4),
  },
  registerContainer: {
    padding: theme.spacing(0, 0, 12.5),
  },
  offeringHeroImg: {
    width: '100%',
  },
  walletBrdrBtm: {
    borderBottom: `solid 2px ${theme.palette.secondary.main}`,
  },
  purchaseNumber: {
    height: 48,
    width: 48,
    color: theme.palette.secondary.main,
    '& svg': {
      transform: 'scale(1.5)',
    },
  },
  purchaseNumberInput: {
    borderRadius: 24,
    boxShadow: '0 0 10px 0 rgba(21, 21, 21, 0.15)',
    width: 48,
    height: 48,
    fontWeight: 600,
    fontSize: '1.75rem',
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    '& input': {
      padding: '4px 0 6px',
      textAlign: 'center',
    },
  },
  purchaseTable: {
    '& th': {
      fontWeight: 600,
      fontSize: '1rem',
      borderBottom: 'solid 1px #000',
    },
    '& tbody tr': {
      height: 16,
      '& td': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
  },
  purchaseDelivery: {
    '& label': {
      marginLeft: 0,
      marginRight: theme.spacing(6),
    },
  },
  rewardsTopThic: {
    fontWeight: 600,
  },
  rewardsTop: {
    border: 'solid 4px #252525',
    backgroundColor: '#151515',
  },
  rewardsTopNumbers: {
    fontSize: '32px',
  },
  rewardsTopText: {
    fontSize: '1.25rem',
  },
  rewardsTopYou: {
    textAlign: 'left',
    [theme.breakpoints.between('md', 'xl')]: {
      textAlign: 'right',
    },
  },
  rewardsMid: {
    backgroundColor: '#353535',
  },
  myWalletSp: {
    fontSize: '1.13rem',
  },
  ccBtnDisabled: {
    backgroundColor: 'rgba(211, 39, 42, 0.5) !important',
    color: '#FFFFFF !important',
  },
  eventsCatIcon2: {
    height: 30,
    width: 52,
  },
  eventsSearchSp: {
    [theme.breakpoints.between('md', 'xl')]: {
      justifyContent: 'space-between',
    },
  },
  eventsDeskMrgn: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginLeft: theme.spacing(),
    },
  },
  searchDropTagsSp: {
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(),
    },
  },
  myticketDropShadow: {
    [theme.breakpoints.between('md', 'xl')]: {
      boxShadow: '0 0 10px 0 rgba(21, 21, 21, 0.15)',
    },
  },
  copyMe: {
    border: `dashed 3px ${theme.palette.secondary.main}`,
  },
  mywalletCopyAddress: {
    fontSize: '1.125rem',
    border: 0,
    textAlign: 'center',
  },
  myWalletDialogTop: {
    backgroundColor: '#7f7f7f',
    padding: theme.spacing(),
  },
  tabMrgn: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },

  cardDate: {
    paddingTop: 6,
    paddingBottom: theme.spacing(2),
  },
  cardDateIcon: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(2),
  },

  // CARD ---- small
  cardContainer_sm: {
    margin: `${theme.spacing(1.5)}px 0`,
    height: 190,
    width: '100%',
    display: 'table',
  },
  cardImageContainer_sm: {
    height: 130,
    width: '100%',
  },
  cardImage_sm: {
    width: '100%',
  },
  cardDetailsContainer_sm: {
    width: '100%',
    /* height: 61px, */
    padding: theme.spacing(1.5),
    backgroundColor: 'rgba(10, 14, 22, 0.5)',
  },

  // CARD ---- medium
  cardContainer_md: {
    margin: `${theme.spacing(1.5)}px 0`,
    height: 190,
    width: '100%',
    display: 'table',
  },
  cardImageContainer_md: {
    height: 130,
    width: '100%',
  },
  cardImage_md: {
    width: '100%',
  },
  cardDetailsContainer_md: {
    width: '100%',
    /* height: 61px, */
    padding: theme.spacing(1.5),
    backgroundColor: 'rgba(10, 14, 22, 0.5)',
  },

  // CARD ---- large
  cardContainer_lg: {
    margin: `${theme.spacing(1.5)}px 0`,
    height: 220,
    width: '100%',
    display: 'table',
  },
  cardImageContainer_lg: {
    height: 220,
    width: '100%',
  },
  cardImage_lg: {
    width: '100%',
  },
  cardDetailsContainer_lg: {
    width: '100%',
    height: 93,
    /* height: 61px, */
    padding: theme.spacing(1.5),
    backgroundColor: 'rgba(10, 14, 22, 0.5)',
  },

  
  
  sidebar: {
    margin: `${theme.spacing(2.5)}px 0 0 0`,
    height: '100%',
  },
  sidebarLeft: {
    padding: `0 ${theme.spacing(2.5)}px 0 0`,
    borderRight: `1px solid #7f7f7f`,

    [theme.breakpoints.between('xs', 'md')]: {
      padding: theme.spacing(0),
      borderRight: 'none',
    },
  },
  sidebarRight: {
    padding: theme.spacing(0, 0, 0, 2.5),

    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.only('md')]: {
      padding: theme.spacing(0),
    },
  },
  sidebarHeader: {
    fontWeight: 'bold',
  },
  sidebarTitleContainer: {
    borderBottom: `1px solid #7f7f7f`,
    padding: `${theme.spacing()}px 0`,
    margin: `0 0 ${theme.spacing(1.5)}px`,
  },

  menuESports: {
    color: '#FFF',
    // color: '#56b1c8',

    textDecoration: 'none',
    transition: 'all 0.5s',

    '&:hover': {
      animation: '$neon3 1.5s ease-in-out infinite alternate',
      color: '#fff',
    },
  },
  menuESportsActive: {
    animation: '$neon3 1.5s ease-in-out infinite alternate',
    color: '#fff',
  },
  menuChannels: {
    // color: '#c18fff',
    color: '#FFF',
    textDecoration: 'none',
    transition: 'all 0.5s',

    '&:hover': {
      animation: '$neon2 1.5s ease-in-out infinite alternate',
      color: '#fff',
    },
  },
  menuChannelsActive: {
    animation: '$neon2 1.5s ease-in-out infinite alternate',
    color: '#fff',
  },
  menuStreaming: {
    color: '#FFF',
    // color: '#ff70cf',
    textDecoration: 'none',
    transition: 'all 0.5s',

    '&:hover': {
      animation: '$neon1 1.5s ease-in-out infinite alternate',
      color: '#fff',
    },
  },
  menuStreamingActive: {
    animation: '$neon1 1.5s ease-in-out infinite alternate',
    color: '#fff',
  },
  '@keyframes neon1': {
    'from': {
      textShadow: `0 0 20px #56b1c8, 0 0 40px #56b1c8, 0 0 15px #56b1c8, 0 0 30px #56b1c8, 0 0 15px #56b1c8`,
    },
    'to': {
      textShadow: `0 0 15px #56b1c8, 0 0 20px #56b1c8, 0 0 25px #56b1c8, 0 0 15px #56b1c8, 0 0 30px #56b1c8`,
    },
  },
  '@keyframes neon2': {
    'from': {
      textShadow: `0 0 20px #c18fff, 0 0 40px #c18fff, 0 0 15px #c18fff, 0 0 30px #c18fff, 0 0 15px #c18fff`,
    },
    'to': {
      textShadow: `0 0 15px #c18fff, 0 0 20px #c18fff, 0 0 25px #c18fff, 0 0 15px #c18fff, 0 0 30px #c18fff`,
    },
  },
  '@keyframes neon3': {
    'from': {
      textShadow: `0 0 20px #ff70cf, 0 0 40px #ff70cf, 0 0 15px #ff70cf, 0 0 30px #ff70cf, 0 0 15px #ff70cf`,
    },
    'to': {
      textShadow: `0 0 15px #ff70cf, 0 0 20px #ff70cf, 0 0 25px #ff70cf, 0 0 15px #ff70cf, 0 0 30px #ff70cf`,
    },
  },
  footerBackground: {
    backgroundColor: '#11161d',
    width: '100%',
    marginTop: 100
  },
  footerContainer: {
    maxWidth: 1920,
    margin: '0 auto',
    padding: '50px 47px 40px',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
      margin: 0,
    },
  },
  footerLogoContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
    },
  },
  footerLogo: {
    marginRight: 16,
    width: 31,
    height: 31,
  },
  footerGroup: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: theme.spacing(2),
    },
  },
  footerKeyline: {
    borderBottom: '1px solid #343940',
    padding: theme.spacing(),
  },
  footerTitle: {
    width: '100%',
    color: '#5A5E64',
  },
  footerTitleCompany: {
    width: '100%',
    color: '#5A5E64',
    '@media (max-width: 396px)': {
      lineHeight: 2.95,
    }
  },
  footerList: {
    listStyle: 'none',
    padding: '0',
    lineHeight: `${theme.spacing(4)}px`,
  },
  footerListItem: {
    fontSize: '0.8rem',
    color: '#5A5E64',
    '&:hover': {
      color: '#fff'
    }
  },
  footerListLink: {
    width: '100%',
  },
  footerIconContainer: {
    textAlign: 'center',
  },
  footerSocialIcon: {
    color: '#5A5E64',
    '&:hover': {
      color: '#FFFFFF'
    }
  },
  footerStayConnected: {
    padding: theme.spacing(1, 0),
  },
  footerCopyWrap: {
    display: 'flex',
    flexDirection: 'row',
    color: '#5A5E64',
    alignItems: 'center',
  },
  footerCopyright: {
    // display: 'flex',
    // alignItems: 'center',
  },
  subscribeContainer: {
    backgroundColor: '#202632',
    padding: theme.spacing(2),
  },
  subscribeButton: {
    margin: theme.spacing(0, 1),
    width: 200,

    [theme.breakpoints.only('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  subscribeTitle: {
    padding: theme.spacing(0, 0, 2, 0),
  },
  subscribeIcon: {
    margin: theme.spacing(1),
  },

  inputBaseRoot: {
    color: '#fff',
    borderBottom: '1px solid #fff',
    width: `calc(100% - ${theme.spacing(2) + 200}px)`,

    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },

    '> input': {},
  },

  testingBorder: {
    border: `1px solid red`,
  },

  storyContainer: {
    backgroundColor: '#eee',
    color: '#333333',
    padding: theme.spacing(0, 0, 12.5),
  },
  storyHeader: {
    position: 'relative',
  },
  storyImageDetailsContainer: {
    // position: 'absolute',
    position: 'relative',
    bottom: 0,
    // backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: '#11161d',
    width: '100%',
    padding: theme.spacing(2.5, 2),

    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1, 0)
    },
  },
  storyImageDetails: {
    padding: theme.spacing(0, 2),
  },
  storyCategory: {
    color: '#FF005B',
    fontSize: 24,
    fontWeight: 600,
    textTransform: 'uppercase',

    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.between('sm')]: {
      fontSize: 18,
    },
  },
  storyBodyContainer: {
    padding: theme.spacing(2),
    maxWidth: 1920,
    margin: '0 auto',
  },
  storyBody: {
    padding: theme.spacing(3, 0, 0),
  },
  storyImage: {
    width: '100%',
    // height: 900,
    paddingTop: '35%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [theme.breakpoints.only('xs')]: {
      paddingTop: '75%'
    },
    // [theme.breakpoints.only('sm')]: {
    //   height: 450,
    // },
    // [theme.breakpoints.only('md')]: {
    //   height: 600,
    // },
    // [theme.breakpoints.only('lg')]: {
    //   height: 900,
    // },
    // [theme.breakpoints.only('xl')]: {
    //   height: 900,
    // },
  },
  storyTitle: {
    fontSize: 48,
    lineHeight: '48px',
    fontWeight: 800,
    // padding: theme.spacing(0, 0, 2),
    color: '#fff',

    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
      lineHeight: '24px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 28,
      lineHeight: '32px',
    },
  },
  storyDetails: {
    color: '#333333',
    fontSize: 18,
    fontWeight: 500,
    padding: theme.spacing(3, 0),

    [theme.breakpoints.only('xs')]: {
      fontSize: 12,
      padding: theme.spacing(2, 0)
    }
  },
  storySummary: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 500,
  },
  storySubtitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 2),
  },
  storyDetail: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 2),
  },
  storyDescription: {
    fontSize: '1rem',
    padding: theme.spacing(0, 0, 2),
    lineHeight: '1.5rem',
  },
  storyShareContainer: {
    padding: theme.spacing(3, 0, 0),
  },
  aniHeroCtaEnter: {
    opacity: 1,
    transition: 'opacity 1500ms',
    [theme.breakpoints.up('sm')]: {
      transform: 'translateY(0%)',
      transition: 'all 1500ms',
    },
  },
  aniHeroCtaEnterDone: {
    opacity: 1,
    maxWidth: 1920,
    position: 'relative',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      transform: 'translateY(0%)',
    },
  },
  aniHeroCtaExit: {
    opacity: 0,
    transition: 'opacity 1500ms',
    [theme.breakpoints.up('sm')]: {
      transform: 'translateY(100%)',
      transition: 'all 1500ms',
    },
  },
  aniHeroCtaExitDone: {
    transform: 'translateY(100%)',
    opacity: 0,
    [theme.breakpoints.up('sm')]: {
      transform: 'translateY(100%)',
    },
  },
  
  storyQuoteContainer: {},
  storyBodyDetails: {
    float: 'none',
  },
  storyBodyContent: {
    float: 'none',
  },
  storyDescriptionHead: {
    color: '#333333',
    fontSize: 30,
    fontWeight: 300,
    lineHeight: '48px',
    padding: theme.spacing(2, 0),
    whiteSpace: 'pre-wrap',

    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
  },
  storyDescriptionBody: {
    color: '#333333',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '30px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
      lineHeight: '22px',
    }
  },
  storyDescriptionImage: {
    height: 450,
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: theme.spacing(3, 0),
  },
  storyBreadcrumbContainer: {
    position: 'absolute',
    top: 20,
    background: 'rgba(0,0,0,0.7)',
    padding: theme.spacing(1),
    borderRadius: 4,
    color: '#fff',

    [theme.breakpoints.only('xs')]: {
      top: '0',
      color: '#fff',
      padding: theme.spacing(),
      position: 'relative',
      background: 'rgb(17, 22, 29)',
      borderRadius: '0',
    }
  },
  storyBreadcrumb: {
    width: '100%',
    margin: '0 auto',
    padding: '0px 16px',
    maxWidth: 1920,
    [theme.breakpoints.only('xs')]: {
      padding: 0
    }
  },
  appbarRoot: {
    boxShadow: 'none !important',
    borderRadius: '0 !important',
    padding: 0,
  },
  appbarPadding: {
    padding: theme.spacing(0, 2),
  },

  footerItem: {
    color: '#5A5E64',
    textDecoration: 'none',

    '&:visited': {
      color: '#5A5E64',
    },

    '&:hover': {
      color: '#FFFFFF',
    },
  },
  slideContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  slideDetails: {
    width: '100%',
    margin: '0 auto',
    padding: 100,
    height: '100%',
    maxWidth: 1920,
    zIndex: 2,

    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      zIndex: 100,
    },
  },

  featuredItemContainer: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  featuredItemDetails: {
    background: 'rgba(0,0,0,0.7)',
    height: '100px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(1, 2),
    wordBreak: 'break-word',
    hyphens: 'auto',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
      height: 'initial'
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(2),
    },
  },
  thumbnailContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  thumbnailDetails: {
    width: '100%',
    margin: '0 auto',
    padding: 100,
    height: '100%',
    maxWidth: 1920,
  },
  // Featured version
  thumbnailItemContainerFeatured: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  thumbnailItemFeatured: {
    position: 'relative',
    backgroundOrigin: 'border-box',
    // background:
    // 'linear-gradient(to top, rgba(0,0,0,0.7) 100px, rgba(0,0,0,0.0) 101px)',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 1,
  },
  thumbnailItemTitleFeatured: {
    left: 7,
    bottom: 7,
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    maxWidth: '98%',
    padding: 16,
    lineHeight: '18px',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  thumbnailItemBgFeatured: {
    position: 'relative',
    top: '-100%',
    zIndex: 0,
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundOrigin: 'border-box',
  },
  thumbnailItemCategory: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  thumbnailItemTitleTextFeatured: {
    hyphens: 'auto',
    fontSize: 18,
    wordBreak: 'break-word',
    fontWeight: 700,
    lineHeight: '20px',
  },
  // Latest version
  thumbnailItemContainerLatest: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    height: 172,
  },
  thumbnailItemLatest: {
    top: 0,
    left: 0,
    width: '100%',
    height: 160,
    zIndex: 1,
    position: 'relative',
    background: 'linear-gradient(to right, rgba(0,0,0,0) 50%, #1F242B 50%)',
  },
  thumbnailItemTitleLatest: {
    position: 'absolute',
    zIndex: 3,
    right: 0,
    maxWidth: '50%',
    width: '50%',
    padding: theme.spacing(1),
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 160,
  },
  thumbnailItemBgLatest: {
    position: 'relative',
    top: -160,
    zIndex: 0,
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundOrigin: 'border-box',
    width: '50%',

    [theme.breakpoints.only('lg')]: {
      width: '50%',
    },
  },
  thumbnailItemTitleTextLatest: {
    hyphens: 'auto',
    fontSize: 16,
    wordBreak: 'break-word',
    fontWeight: 700,
    lineHeight: '18px',
  },

  thumbnailItemActiveFeatured: {
    borderLeft: '8px solid #ff005b',
    backgroundOrigin: 'border-box',

    '&:before': {
      content: "''",
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRight: '10px solid #ff005b',
      position: 'absolute',
      left: -14,
      top: 'calc(50% - 10px)',
    },
  },

  thumbnailItemActiveLatest: {
    borderTop: '8px solid #ff005b',
    backgroundOrigin: 'border-box',

    '&:before': {
      content: "''",
      borderLeft: '10px solid transparent',
      borderBottom: '10px solid #ff005b',
      borderRight: '10px solid transparent',
      position: 'absolute',
      left: 'calc(50% - 10px)',
      top: -14,
    },
  },

  

  sliderTitle: {
    fontWeight: 700,
    width: '100%',
    wordBreak: 'break-word',
    hyphens: 'auto',
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: '24px',

    [theme.breakpoints.only('xs')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 18,
      fontWeight: 700,
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
    },
  },
  sliderCategoryMain: {
    color: '#56B1C8',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  sliderTitleLink: {
    color: '#ff005b',
  },
  sliderTextLink: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  sliderSubtitle: {
    padding: theme.spacing(1, 0, 2),
    whiteSpace: 'pre-wrap',

    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
      lineHeight: '22px',
      margin: 'inherit',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.9rem',
      lineHeight: '1.1rem',
    },
  },
  sliderDescription: {
    padding: theme.spacing(1, 0, 2),
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.9rem',
      lineHeight: '1.1rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.9rem',
      lineHeight: '1.1rem',
    },
  },
  sliderCTAContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  sliderCTALink: {
    textDecoration: 'none',
  },
  sliderCTARoot: {
    // width: 150,
    color: '#fff',
    backgroundColor: '#ff005b',
    padding: theme.spacing(2, 4),
    minWidth: 263,
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1, 2),
      minWidth: 150,
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  sliderCTALabel: {
    textDecoration: 'none',
  },
  sliderCTAText: {
    padding: theme.spacing(0, 0, 0, 1),
    fontWeight: 500,
    fontSize: '1.3rem',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0, 0, 0, 0.5),
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(0, 0, 0, 0.5),
      fontSize: 14,
    },
  },
  genericLink: {
    textDecoration: 'none',
  },
  genericLinkWhite: {
    textDecoration: 'none',
    color: '#fff',

    '&:active': {
      color: '#fff',
    },
    '&:hover': {
      color: '#fff',
    },
  },
  maxWidthCentered: {
    maxWidth: 1920,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  fullWidth: {
    width: '100%',
  },

})

export default styles 