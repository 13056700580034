import { createMuiTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: {
      main: '#ff005b',
    },
    background: {
      default: '#11161d',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#ff005b',
    },
    action: {
      active: '#ff005b',
      hover: '#ff005b',
      selected: '#ff005b',
      disabled: '#ff005b',
    },
    error: {
      main: '#ff0000'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Montserrat', 'Helvetica', 'Arial', 'sans-serif'",
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#ff005b',
          color: '#fff',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#ff005b',
          color: '#fff',
        },
      },
      root: {
        // backgroundColor: '#ff005b',
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: '#707070',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '1.25rem',
        fontWeight: 500,
        '@media (min-width: 960px)': {
          fontSize: '2.5rem',
          fontWeight: 600,
        },
      },
      h2: {
        fontSize: '1.375rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.375rem',
        color: '#151515',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.375rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.375rem',
        fontWeight: 600,
        '@media (min-width: 960px)': {
          fontSize: '1.5rem',
        },
      },
      h6: {
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: '1.12rem',
        fontWeight: 400,
      },
      body1: {
        fontSize: '0.818rem',
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        '@media (min-width: 960px)': {
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
        fontWeight: 600,
        borderRadius: 0,
        '& svg': {
          height: 16,
          width: 16,
        },
        '&$disabled': {
          color: '#FFFFFF !important',
          backgroundColor: '#151515 !important',
        },
        '&:hover': {
          color: '#fff !important',
          backgroundColor: '#11161d !important',
        },
      },
      contained: {
        fontSize: '1rem',
        fontWeight: 600,
        padding: '11px 11px !important',
        backgroundColor: '#FF005B',
      },
      outlined: {
        fontSize: '1rem',
        color: '#151515',
        padding: '10px 10px !important',
        fontWeight: 600,
        boxShadow: '0 0 12px 0 rgba(21, 21, 21, 0.25)',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow:
          '0 0 10px 0 rgba(21, 21, 21, 0.5), 0 0 10px 0 rgba(21, 21, 21, 0.75)',
        padding: '0px 16px',
        '@media (min-width: 960px)': {
          boxShadow: '0 0 10px 0 rgba(21, 21, 21, 0.75)',
        },
      },
    },
    MuiDrawer: {
      paper: {
        width: '100%',
        maxWidth: '600px',
        fontSize: '1rem',
        backgroundColor: '#151515',
        '& span': {
          fontSize: '1.8rem',
        },
        '@media (min-width: 960px)': {
          width: '275px',
          height: 'auto',
          boxShadow: '0 0 12px 0 rgba(21, 21, 21, 0.75)',
          backgroundColor: 'rgba(21, 21, 21, 0.75)',
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '50px',
        '@media (min-width: 600px)': {
          minHeight: '50px',
        },
        '@media (min-width: 960px)': {
          minHeight: '75px',
        },
      },
      gutters: {
        padding: '0 !important',
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        fontSize: '1.12rem',
        fontWeight: 600,
      },
      labelContainer: {
        padding: '16px 24px !important',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
      },
    },
    MuiPrivateTabIndicator: {
      root: {
        height: 4,
      },
    },
    MuiTableSortLabel: {
      root: {
        fontSize: '1.12rem',
        letterSpacing: 0.2,
      },
    },
    MuiTableCell: {
      body: {
        fontSize: '1rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.125rem',
        fontWeight: 600,
      },
    },
    MuiFormHelperText: {
      root: {
        margin: '0px !important',
        textAlign: 'center'
      }
    },
    MuiTextField: {
      root: {
        '&:hover': {
          '&::before': {
            content: "''",
            boxShadow: '0 0 20px 3px #ff005b',
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '50px',
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        padding: '8px 0 16px',
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
        '&:disabled': {
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 30,
      },
      root: {
        borderRadius: 30,
        backgroundColor: '#1C222A',
        boxShadow: '#454D59 5px -5px 0px 2px',
        '&$focused $notchedOutline': {
          boxShadow: '#FF005B 5px -5px 0px 2px',
        },
        '&:hover $notchedOutline': {
          boxShadow: '#454D59 5px -5px 0px 2px',
        },
        '&$focused': {
          '&::before': {
            content: "''",
            boxShadow: '0 0 20px 3px #ff005b',
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '50px',
            left: 0,
          },
        },
        '&$disabled': {
          '& $notchedOutline': {
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 30,
        backgroundColor: '#1C222A',
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: 'transparent',
        color: '#45494e',
        '&$expanded': {
          border: `3px solid #ff005b`,
          boxShadow: '0 0 20px 3px #ff005b',
          color: '#FFFFFF',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: 'column',
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        justifyContent: 'space-between',
      },
      label: {
        fontWeight: 600,
      },
    },
    MuiSnackbarContent: {
      message: {
        padding: 0,
        paddingRight: 24,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialog: {
      paperFullScreen: {
        backgroundColor: '#11161d',
      },
    },
    MuiCheckbox: {
      root: {
        borderRadius: 5,
        color: 'transparent',
        backgroundColor: '#1C222A',
        padding: 0,
        width: 24,
        height: 24,
        '&::before': {
          content: '""',
          position: 'absolute',
          width: 24,
          height: 24,
          background: '#454D59',
          borderRadius: 5,
          left: 4,
          top: -2,
          zIndex: -1,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
      },
      edgeEnd: {
        marginRight: 0,
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#ff005b',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#FFFFFF',
        padding: '0 24px',
      },
      toolbarBtnSelected: {
        color: '#FFFFFF',
        padding: '0 24px',
      }
    },
    MuiPickersDay: {
      day: {
        color: '#9e9e9e',
      },
      daySelected: {
        backgroundColor: '#ff005b',
        color: '#FFFFFF'
      },
      dayDisabled: {
        color: '#9e9e9e',
      },
      current: {
        color: '#9e9e9e',
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: '#11161d'
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#9e9e9e',
      }
    }
  },
})

export default theme
