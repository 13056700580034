// # IMPORT APPLICATION CONSTANTS
// import * as constant from 'constants'
import {
  TOURNAMENTS_GET_STARTED,
  TOURNAMENTS_GET_SUCCESS,
  TOURNAMENTS_GET_FAILED
} from 'constants'

const initialState = {
  data: null,
  loaded: false,
}

export default function tournaments(state = initialState, action) {
  switch (action.type) {

    case TOURNAMENTS_GET_STARTED:
      return {
        ...state,
      }

    case TOURNAMENTS_GET_SUCCESS:
      return{
        ...state,
        data: action.payload,
        loaded: true,
      }

    case TOURNAMENTS_GET_FAILED:
      return {
        ...state,
        loaded: false
      }

    default:
      return state

  }
}
