import React, { useEffect } from 'react'
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link, withRouter } from 'react-router-dom'
import styles from 'Styles'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { userGet, userGetToken } from 'actions/user'

const MenuDrawer = (props) => {
  const { classes } = props

  const dispatch = useDispatch()
  const userData = useSelector((state) => state.user)

  useEffect(() => {
    if(window.sessionStorage.getItem('userToken') && !userData.userToken) {
      dispatch(userGetToken())
    }
    if(!userData.loaded) {
      // get user details if not already in redux state
      dispatch(userGet())
    }
  }, [])

  return (
    <div className={classes.drawerSpacer}>
      <List>
        {/* <Divider className={classes.drawerDivider} /> */}
        <ListItem style={{ padding: 0 }}>
          <ListItemIcon
            style={{
              marginLeft: 'auto',
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              size='1x'
              style={{
                color: '#ffffff',
              }}
            />
          </ListItemIcon>
        </ListItem>
        <ListItem className={classes.drawerList}>
          <Link
            to='/esports'
            className={classes.menuESports}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <ListItemText
              classes={{
                primary: classNames(classes.drawerText),
              }}
              primary='ESPORTS'
            />
          </Link>
        </ListItem>

        <Divider classes={{ root: classes.drawerDivider }} />

        <ListItem className={classes.drawerList}>
          <Link
            to='/channels'
            className={classes.menuChannels}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <ListItemText
              classes={{
                primary: classNames(classes.drawerText),
              }}
              primary='CHANNELS'
            />
          </Link>
        </ListItem>

        <Divider classes={{ root: classes.drawerDivider }} />

        <ListItem className={classes.drawerList}>
          <Link
            to='/streaming'
            className={classes.menuStreaming}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <ListItemText
              classes={{
                primary: classNames(classes.drawerText),
              }}
              primary='STREAMING'
            />
          </Link>
        </ListItem>

        <Divider classes={{ root: classes.drawerDivider }} />

        <ListItem className={classes.drawerList}>
          {userData.userToken ? (
            <Link
              to='/sign-out'
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <ListItemText
                classes={{
                  primary: classNames(classes.drawerText),
                }}
                primary='SIGN OUT'
              />
            </Link>
          ) : (
            <Link
              to='/sign-in'
              style={{ textDecoration: 'none', width: '100%' }}
            >
              <ListItemText
                classes={{
                  primary: classNames(classes.drawerText),
                }}
                primary='SIGN IN'
              />
            </Link>
          )}
        </ListItem>

        <Divider classes={{ root: classes.drawerDivider }} />
      </List>
    </div>
  )
}

MenuDrawer.defaultProps = {
  classes: {},
}
MenuDrawer.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(withRouter(MenuDrawer))
