// # IMPORT APPLICATION CONSTANTS
// import * as constant from 'constants'
import {
  STREAMING_GET_STARTED,
  STREAMING_GET_SUCCESS,
  STREAMING_GET_FAILED
} from 'constants'

const initialState = {
  data: null,
  loaded: false,
}

export default function streaming(state = initialState, action) {
  switch (action.type) {

    case STREAMING_GET_STARTED:
      return {
        ...state,
      }

    case STREAMING_GET_SUCCESS:
      return{
        ...state,
        data: action.payload,
        loaded: true,
      }

    case STREAMING_GET_FAILED:
      return {
        ...state,
        loaded: false
      }

    default:
      return state

  }
}
