/* eslint-disable */
export const AVATAR_GET_STARTED = "AVATAR_GET_STARTED"
export const AVATAR_GET_SUCCESS = "AVATAR_GET_SUCCESS"
export const AVATAR_GET_FAILED = "AVATAR_GET_FAILED"

export const BANNER_GET_STARTED = "BANNER_GET_STARTED"
export const BANNER_GET_SUCCESS = "BANNER_GET_SUCCESS"
export const BANNER_GET_FAILED = "BANNER_GET_FAILED"

export const STREAMING_GET_STARTED = "STREAMING_GET_STARTED"
export const STREAMING_GET_SUCCESS = "STREAMING_GET_SUCCESS"
export const STREAMING_GET_FAILED = "STREAMING_GET_FAILED"

export const STORIES_GET_STARTED = "STORIES_GET_STARTED"
export const STORIES_GET_SUCCESS = "STORIES_GET_SUCCESS"
export const STORIES_GET_FAILED = "STORIES_GET_FAILED"

export const TOURNAMENTS_GET_STARTED = "TOURNAMENTS_GET_STARTED"
export const TOURNAMENTS_GET_SUCCESS = "TOURNAMENTS_GET_SUCCESS"
export const TOURNAMENTS_GET_FAILED = "TOURNAMENTS_GET_FAILED"

export const USER_GET_STARTED = "USER_GET_STARTED"
export const USER_GET_SUCCESS = "USER_GET_SUCCESS"
export const USER_GET_FAILED = "USER_GET_FAILED"
export const USER_SET_TOKEN = "USER_SET_TOKEN"
export const USER_UPDATE_PROFILE_IMAGES = "USER_UPDATE_PROFILE_IMAGES"
export const USER_SIGN_IN = "USER_SIGN_IN"
export const USER_SIGN_OUT = "USER_SIGN_OUT"
export const USER_UPDATE_SETTINGS_STARTED = "USER_UPDATE_SETTINGS_STARTED"
export const USER_UPDATE_SETTINGS_SUCCESS = "USER_UPDATE_SETTINGS_SUCCESS"
export const USER_UPDATE_SETTINGS_FAILED = "USER_UPDATE_SETTINGS_FAILED"
export const USER_UPDATE_PASSWORD_STARTED = "USER_UPDATE_PASSWORD_STARTED"
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS"
export const USER_UPDATE_PASSWORD_FAILED = "USER_UPDATE_PASSWORD_FAILED"
/* eslint-enable */
