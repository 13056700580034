/* eslint-disable */
import axios from 'axios'
import * as constant from 'constants'
import merge from 'deepmerge'

export const userGet = () => {
  const endpoint1 = '/apiv2/user/'
  const endpoint2 = '/apiv2/user/profile/'

  return (dispatch) => {
    dispatch({
      type: constant.USER_GET_STARTED,
    })

    // get user & profile then send as merged object
    axios.all([
      axios.get(endpoint1),
      axios.get(endpoint2),
    ])
    .then(axios.spread((user, profile) => {
      if (user.status !== 200 && profile.status !== 200) {
        dispatch({
          type: constant.USER_GET_FAILED,
        })
        console.error('Fetch did not return 200')
      }

      if (user.data.state && profile.data.state) {
        const { user: userData } = user.data.data
        const { profile: profileData } = profile.data.data

        dispatch({
          type: constant.USER_GET_SUCCESS,
          payload: merge(userData, profileData),
        })
      } else {
        dispatch({
          type: constant.USER_GET_FAILED,
        })
        console.warn('Data state not True')
      }
    }))
    .catch((e) => {
      dispatch({
        type: constant.USER_GET_FAILED,
      })
      console.error('fetch failed', e)
    })
  }
}

export const userSignIn = (props, setSubmitting, callback) => {
  const {
    username,
    password,
    token,
  } = props

  const endpoint = '/apiv2/user/login/'

  return (dispatch) => {

    axios({
      method: 'post',
      url: endpoint,
      headers: {},
      data: {
        username,
        password,
        token
      }
    })
    .then((response) => {
      if (response.status === 304) {
        return true
      } else if (response.status !== 200) {
        console.warn('Non 200 or 304 response')
      }
      return response.data
    })
    .then((response) => {
      if (response.state) {
        const userToken = response.data.user_uid
        setSubmitting(false)

        dispatch({
          type: constant.USER_SIGN_IN,
          payload: userToken
        })

        // save in session storage
        window.sessionStorage.setItem('userToken', userToken)
        dispatch(userGet())

        callback()
      }
    })
    .catch((e) => {
      console.error('could not log in', e)
    })
  }
}

export const userGetToken = () => ({
  type: constant.USER_SET_TOKEN,
  payload: window.sessionStorage.getItem('userToken')
})

export const userUpdateProfileImages = (props) => {
  const {
    activeAvatarUID,
    activeBannerUID,
    enqueueSnackbar
  } = props

  const endpoint = '/apiv2/user/profile/'

  return (dispatch, getState) => {
    const user = getState().user
    const {
      avatar_uid: avatarUIDDefault,
      banner_uid: bannerUIDDefault,
    } = user

    axios({
      method: 'post',
      url: endpoint,
      headers: {},
      data: {
        avatar_uid: activeAvatarUID || avatarUIDDefault,
        banner_uid: activeBannerUID || bannerUIDDefault,
      }
    })
    .then((response) => {
      if (response.status !== 200) {
        console.error('Non 200 response')
      }
      return response
    })
    .then((response) => {
      // console.error('userUpdateProfileImages:response', response)

      if (response.data.state) {
        enqueueSnackbar('Profile Updated.', { variant: 'success' })
        // console.warn('save successful', user)
        dispatch({
          type: constant.USER_UPDATE_PROFILE_IMAGES
        })
        dispatch(userGet())
      }
    })
    .catch((e) => {
      console.error('could not save avatar/banner', e)
    })
  }
}

export const userSignOut = (props) => {
  return (dispatch, getState) => {
    const {
      userToken,
      enqueueSnackbar
    } = props
    const endpoint = '/apiv2/user/logout/'

    axios({
      method: 'post',
      url: endpoint,
      headers: {},
      data: {
        user_uid: userToken
      }
    })
    .then((response) => {
      if (response.status === 304) {
        return true
        // } else if (response.status !== 200) {
        //   this.setState({ error: 'Non 200 or 304 response' })
      }
      return response
    })
    .then(() => {
      dispatch({
        type: constant.USER_SIGN_OUT,
      })

      // console.log('SignOut data', data)
      enqueueSnackbar('Successfully signed out.', {
        variant: 'success',
      })
    })
  }
}

export const updateSettings = (props) => {
  return (dispatch) => {
    const {
      endpoint, 
      username,
      country,
      enqueueSnackbar,
      setSubmitting
    } = props

    dispatch({
      type: constant.USER_UPDATE_SETTINGS_STARTED
    })

    axios({
      method: 'post',
      url: endpoint,
      headers: {},
      data: {
        username: username !== '' ? username : undefined,
        country_name: country !== '' ? country : undefined,
      }
    })
      .then((response) => {
        console.log('response', response)
        if (response.status === 304) {
          return true
        } else if (response.status !== 200) {
          console.warn('Non 200 or 304 response')
        }
        return response.data
      })
      .then((data) => {
        console.error('data',data)
        if (data.state) {
          dispatch({
            type: constant.USER_UPDATE_SETTINGS_SUCCESS
          })
          console.warn('Submit 1 Success!')
          setSubmitting(false)
          userGet()
        }
        enqueueSnackbar('Update settings successful.', { variant: 'success' })
      })
      .catch((e) => {
        console.error('Error when trying to save settings', e)
        dispatch({
          type: constant.USER_UPDATE_SETTINGS_FAILED
        })
      })
  }
} 

export const updatePassword = (props) => {
  return (dispatch) => {
    const {
      endpoint, 
      oldPassword,
      password,
      confirmPassword,
      enqueueSnackbar,
    } = props

    dispatch({
      type: constant.USER_UPDATE_PASSWORD_STARTED
    })

    axios({
      method: 'post',
      url: endpoint,
      headers: {},
      data: {
        oldpassword: oldPassword,
        password: password,
        passwordconfirm: confirmPassword,
      }
    })
      .then((response) => {
        if (response.status === 304) {
          return true
        } else if (response.status !== 200) {
          console.error('Non 200 or 304 response')
        }
        return response.data
      })
      .then((data) => {
        console.error(data.error)
        if (data.state) {
          console.warn('Submit 2 Success!')
          dispatch({
            type: constant.USER_UPDATE_PASSWORD_SUCCESS
          })
        }
        enqueueSnackbar('Update password successful.', { variant: 'success' })
      })
      .catch((e) => {
        console.error('Error when trying to save password', e)
        dispatch({
          type: constant.USER_UPDATE_PASSWORD_FAILED
        })
      })
  }
}
/* eslint-enable */