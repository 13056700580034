import React, { memo, useState, useEffect } from 'react'
import {
  Popper,
  Fade,
  Paper,
  Typography,
  ClickAwayListener,
} from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import styles from 'Styles'
import DefaultProfileAvatar from 'assets/design-elements/profile/default-avatar.jpg'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
// import { UserContext } from 'components/UserContext'
import { useSelector, useDispatch } from 'react-redux'
import { userGet, userGetToken } from 'actions/user'
import { avatarGet } from 'actions/avatar'

const useStyles = makeStyles((theme) => ({
  root: {},
  popoverRoot: {
    zIndex: 1310, //override appbar's z index
    paddingTop: 15,
    color: '#2b323b',

    '&:before': {
      content: "''",
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 15px 8px',
      borderColor: 'transparent transparent #2b323b transparent',
      top: 0,
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      transition: 'opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },

  popoverOpenRoot: {
    '&:before': {
      opacity: 1,
      transition: 'opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },

  popperItem: {
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 0),
    textAlign: 'center',
  },

  paperRoot: {
    padding: theme.spacing(1),
    backgroundColor: '#2b323b',
    width: 100,
  },
  profileAvatarHeader: {
    zIndex: 1,
    backgroundSize: 'contain',
    borderRadius: 150,
    cursor: 'pointer',
  },
  profileAvatarHeaderBoxShadow: {
    zIndex: 1,
    backgroundSize: 'contain',
    borderRadius: 150,
    cursor: 'pointer',
    '&:before': {
      content: "''",
      width: 250,
      height: 250,
      display: 'block',
      borderRadius: 150,
      boxShadow: '-2px -1px 59px black',
      [theme.breakpoints.between('xs', 'sm')]: {
        display: 'none',
      },
    },
  },
  profileAvatarsm: {
    height: 35,
    width: 35,
    margin: theme.spacing(0, 2.5),
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.between('xs', 'sm')]: {
      height: 35,
      width: 35,
      margin: theme.spacing(0.5, 2.5),
    },
  },
  profileAvatarmd2: {
    height: 150,
    width: 150,
    border: '4px solid #1f1f1f',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  profileAvatarmd: {
    height: 112,
    width: 112,
    border: '4px solid #1f1f1f',
    margin: '0 auto',
  },
  profileAvatarlg: {
    height: 250,
    width: 250,
  },
}))

const Avatar = memo((props) => {
  const {
    classes,
    avatarUID,
    size,
    purpose,
    history,
    boxShadow,
  } = props
  let imageURL = null
  const localStyles = useStyles()
  const dispatch = useDispatch()
  // state
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [placement, setPlacement] = useState('bottom')
  // const [avatarData, setAvatarData] = useState(null)
  // selectors
  const avatarData = useSelector((state) => state.avatar)
  const userData = useSelector((state) => state.user)

  const handleMenuClick = () => (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
    setPlacement('bottom')
  }
  const handleRedirect = () => {
    history.push(`/profile`)
  }
  let handleAvatarClick = () => { } //disabled by default
  const handleClickAway = () => {
    setOpen(false)
  }
  // const value = useContext(UserContext)

  switch (purpose) {
    case 'menu':
      handleAvatarClick = handleMenuClick()
      break
    case 'link':
      handleAvatarClick = handleRedirect
      break
    case 'disabled':
      break
    default:
      break
  }

  useEffect(() => {
    if(window.sessionStorage.getItem('userToken') && !userData.userToken) {
      dispatch(userGetToken())
    }
    if(!userData.loaded) {
      // get user details if not already in redux state
      dispatch(userGet())
    }
    // get avatar data if not alreaday in redux state
    if(!avatarData.loaded) {
      dispatch(avatarGet())
    }
  }, [userData])

  if (avatarData.data && avatarData.data.length > 0) {
    const avatarItem = avatarData.data.find((item) => item['assetmap_uid'] === avatarUID)

    if (avatarItem) {
      imageURL = avatarItem.url
    }
  }

  return (
    <>
      {purpose === 'menu' && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
          className={classNames(localStyles.popoverRoot, {
            [localStyles.popoverOpenRoot]: open,
          })}
        >
          {({ TransitionProps }) => (
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              <Paper className={localStyles.paperRoot}>
                <Link
                  to='/profile'
                  className={classes.genericLinkWhite}
                >
                  <Typography className={localStyles.popperItem}>
                    Profile
                  </Typography>
                </Link>
                <Link
                  to='/settings'
                  className={classes.genericLinkWhite}
                >
                  <Typography className={localStyles.popperItem}>
                    Settings
                  </Typography>
                </Link>
                <Link
                  to='/sign-out'
                  className={classes.genericLinkWhite}
                >
                  <Typography className={localStyles.popperItem}>
                    Sign out
                  </Typography>
                </Link>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classNames(localStyles.profileAvatarContainer)}>
          <div
            style={{
              backgroundImage: `url(${imageURL || DefaultProfileAvatar})`,
            }}
            onClick={handleAvatarClick}
            aria-label='Menu'
            className={classNames(
              localStyles.profileAvatarHeader,
              localStyles[`profileAvatar${size}`],
              localStyles[`settingsAvatar${size}`],
              boxShadow && localStyles.profileAvatarHeaderBoxShadow
            )}
          />
        </div>
      </ClickAwayListener>
    </>
  )
})

Avatar.defaultProps = {
  classes: {},
  history: {},
  avatarUID: '',
  size: 'sm',
  purpose: 'menu',
  boxShadow: null,
  dispatch: () => {},
}

Avatar.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  history: PropTypes.object,
  avatarUID: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  purpose: PropTypes.oneOf(['menu', 'link', 'disabled']),
  boxShadow: PropTypes.any,
}

export default withRouter(withStyles(styles)(Avatar))
