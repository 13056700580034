
const returnRightTrans = (data, lang) => {
  /* Take in a list with translations and return dict which matches language.
   *
   * [
   *  {
   *    ...
   *    translations: [
   *      {
   *        description: "thing",
   *        title: "other",
   *        language: "en"
   *      },
   *      {
   *        description: "thing",
   *        title: "other",
   *        language: "fr"
   *      }
   *    ]
   *  }
   * ]
   *
   */
  let trans = { title: '', description: '' }
  if (data) {
    trans = data.translations.filter((itm) => itm.language === lang)[0]
    if (!trans && data.translations.length) {
      trans = data.translation[0]
    }
  }
  return trans
}

const returnRestrictTags = (data) => data.tags.filter((tag) => tag.nature === 'limitation')

const returnRightCurrency = (data, defCurr) => {
  /* Take an object which has currency in and return one which matches defCurr or usd.
   *  {
   *    ...
   *    "currency":{
   *       "usd":{
   *          "int":0,
   *          "display":"0.00"
   *       },
   *       "slx":{
   *          "int":0,
   *          "display":"0.000000"
   *       }
   *    },
   * */
  let currency = { int: 0, display: 0 }
  if (data) {
    if (data.currency && data.currency[defCurr]) {
      currency = data.currency[defCurr]
    } else if (data[defCurr]) {
      currency = data[defCurr]
    } else if (data.currency && data.currency.usd) {
      currency = data.currency.usd
    } else if (data.usd) {
      currency = data.usd
    }
  }
  return currency
}

const returnRightCurrencyKey = (data, defCurr) => {
  if (data) {
    if (data.currency && defCurr in data.currency) {
      return defCurr.toUpperCase()
    } if (defCurr in data) {
      return defCurr.toUpperCase()
    }
  }
  return 'usd'.toUpperCase()
}

export {
  returnRightTrans, returnRestrictTags, returnRightCurrency, returnRightCurrencyKey,
}
