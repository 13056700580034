import React from 'react'
import { PropTypes } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  sectionTitle2Container: {
    width: '100%',
    height: 100,
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.only('xs')]: {
      height: 125,
    },
  },
  sectionTitle2First: {
    width: '100%',
    fontSize: 36,
    fontWeight: 800,
    position: 'absolute',
    color: '#FFF',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.only('xs')]: {
      fontSize: 28,
    }
  },
  sectionTitle2Second: {
    width: '100%',
    fontSize: 88,
    fontWeight: 800,
    color: '#e9e9e9',
    opacity: 0.05,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.only('xs')]: {
      fontSize: 52,
    }
  },
})

const SectionTitle2 = (props) => {
  const { classes, children, back } = props

  return (
    <div className={classes.sectionTitle2Container}>
      <div
        style={{
          textTransform: 'uppercase',
        }}
        className={classes.sectionTitle2First}
      >
        {children}
      </div>
      <div
        style={{
          textTransform: 'uppercase',
        }}
        className={classes.sectionTitle2Second}
      >
        {back}
      </div>
    </div>
  )
}

SectionTitle2.defaultProps = {
  classes: {},
  children: null,
  back: null,
}
SectionTitle2.propTypes = {
  children: PropTypes.any,
  back: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(styles)(SectionTitle2)
