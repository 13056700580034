import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Drawer,
  Hidden,
  Typography,
  IconButton,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  MenuDrawer,
  Avatar,
  Button,
} from 'components'
import SlatePlayIcon from 'assets/icons/Logos/Slate-Play-Logo.svg'
import MenuIcon from 'assets/Header-Hamburger-Menu.svg'
import styles from 'Styles'
import classNames from 'classnames'
import { PropTypes } from 'prop-types'
import withWidth from '@material-ui/core/withWidth'
import { connect } from 'react-redux'
import { userGet, userGetToken } from 'actions/user'
import { avatarGet } from 'actions/avatar'

class Header extends React.Component {
  constructor(props) {
    super(props)
    const { classes } = this.props
    this.state = {
      drawer: false,
      esportsClass: [classes.headerDeskLink],
      channelsClass: [classes.headerDeskLink],
      streamingClass: [classes.headerDeskLink],
    }
  }

  componentDidMount() {
    const {
      user,
      getUser,
      getUserToken,
      avatar,
      getAvatars,
    } = this.props
    
    if(window.sessionStorage.getItem('userToken') && !user.userToken) {
      getUserToken()
    }

    if(!user.loaded && user.userToken) {
      getUser()
    }

    if(!avatar.loaded && user.userToken) {
      getAvatars()
    }

    this.doHighlight()
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location !== prevProps.location) {
      this.doHighlight()
    }
  }

  doHighlight() {
    const { classes, location } = this.props
    const esportsClass = [classes.headerDeskLink]
    const channelsClass = [classes.headerDeskLink]
    const streamingClass = [classes.headerDeskLink]

    if (location.pathname === '/esports') {
      esportsClass.push(classes.menuESportsActive)
    }
    if (location.pathname === '/channels') {
      channelsClass.push(classes.menuChannelsActive)
    }
    if (location.pathname === '/streaming') {
      streamingClass.push(classes.menuStreamingActive)
    }

    this.setState({
      esportsClass,
      channelsClass,
      streamingClass,
    })
  }

  flipDrawer() {
    const { drawer } = this.state
    this.setState({ drawer: !drawer })
  }

  closeDrawer() {
    const { drawer } = this.state
    if (drawer) {
      this.setState({ drawer: false })
    }
  }

  render() {
    const {
      esportsClass,
      channelsClass,
      streamingClass,
      drawer
    } = this.state
    const {
      classes,
      width,
      user,
    } = this.props

    return (
      <>
        {window.location.pathname !== '/' && (
          <AppBar
            className={classes.appbar}
            position='fixed'
            onClick={() => this.closeDrawer()}
            onKeyDown={() => this.closeDrawer()}
            classes={{
              root: classes.appbarRoot,
            }}
            style={{ zIndex: 1306 }}
          >
            <Toolbar
              disableGutters
              style={{
                justifyContent: 'center'
              }}
            >
              <div
                className={classNames(classes.maxWidth, classes.appbarPadding)}
                style={{ display: 'flex' }}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center'
                  }}
                >
                  <Link
                    to='/'
                    className={classNames(
                      classes.appbarLogoWrap,
                      classes.appbarLogoBtn
                    )}
                  >
                    <SlatePlayIcon className={classes.slateLogo} />
                  </Link>
                  <Hidden smDown>
                    <Link
                      to='/esports'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        component='div'
                        display='inline'
                        className={classNames(
                          esportsClass,
                          classes.menuESports
                        )}
                      >
                        <span>ESPORTS</span>
                      </Typography>
                    </Link>
                    <Link
                      to='/channels'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        component='div'
                        display='inline'
                        className={classNames(channelsClass, classes.menuChannels)}
                      >
                        <span>CHANNELS</span>
                      </Typography>
                    </Link>
                    <Link
                      to='/streaming'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        component='div'
                        display='inline'
                        className={classNames(
                          streamingClass,
                          classes.menuStreaming
                        )}
                      >
                        <span>STREAMING</span>
                      </Typography>
                    </Link>
                  </Hidden>
                </Grid>
                <Grid
                  container
                  alignItems='center'
                  justify='flex-end'
                >

                  {
                    user.userToken && user.loaded ? (
                      <>
                        <Avatar
                          avatarUID={user.data['avatar_uid']}
                          size='sm'
                          purpose='menu'
                        />
                      </>
                    ) :
                      (
                        <Link
                          to='/sign-in'
                          className={classes.genericLink}
                        >
                          {width === 'xs' ? null :
                            (
                              <Button
                                variant='secondary--round--pinkBorder'
                                style={{
                                  padding: '0 16px',
                                }}
                              >
                                SIGN IN
                              </Button>
                            )
                          }
                        </Link>
                      )
                  }

                  <Hidden only={['md', 'lg', 'xl']}>
                    <IconButton
                      onClick={() => this.flipDrawer()}
                      color='inherit'
                      aria-label='Menu'
                      style={{ padding: 12 }}
                    >
                      <MenuIcon className={classes.menu} />
                    </IconButton>
                  </Hidden>
                </Grid>
              </div>
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          anchor='right'
          open={drawer}
          onClose={() => this.flipDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{
            zIndex: 1305,
          }}
        >
          <div
            tabIndex={0}
            role='button'
            onClick={() => this.flipDrawer(false)}
            onKeyDown={() => this.flipDrawer(false)}
          >
            <MenuDrawer />
          </div>
        </Drawer>
      </>
    )
  }
}

Header.defaultProps = {
  location: {},
  classes: {},
  user: {},
  avatar: {},
  width: null,
  getUser: () => {},
  getUserToken: () => {},
  getAvatars: () => {},
}
Header.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
  user: PropTypes.object,
  avatar: PropTypes.object,
  width: PropTypes.string,
  getUser: PropTypes.func,
  getUserToken: PropTypes.func,
  getAvatars: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  avatar: state.avatar,
})

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(userGet()),
  getUserToken: () => dispatch(userGetToken()),
  getAvatars: () => dispatch(avatarGet()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(withWidth()(Header))))
