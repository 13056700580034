/* eslint-disable */
import axios from 'axios'
import * as constant from 'constants'

export const avatarGet = () => {
  return (dispatch) => {
    dispatch({
      type: constant.AVATAR_GET_STARTED,
    })
    
    const endpoint = '/apiv2/user/avatars/'

    axios.get(endpoint, { credentials: 'include' })
      .then((response) => {
        if (response.status !== 200) {
          console.warn('Fetch did not return 200')
        }
        return response.data
      })
      .then((data) => {
        if (data.state) {
          dispatch({
            type: constant.AVATAR_GET_SUCCESS,
            payload: data.data,
          })
        } else {
          console.error('fetch failed on:', endpoint, '\n', e)
          dispatch({
            type: constant.AVATAR_GET_FAILED,
          })
        }
      })
      .catch((e) => {
        console.error('fetch failed on:', endpoint, '\n', e)
        dispatch({
          type: constant.AVATAR_GET_FAILED,
        })
      })
  }
}
/* eslint-enable */