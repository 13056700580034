import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

// Taken from
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
}

export default withRouter(ScrollToTop)
