import { applyMiddleware, compose, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'
import rootReducer from '../reducers'

export default function configureStore(preloadedState) {
  const middlewares = [ReduxThunk] // add more middlewares here
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(
    ...enhancers,
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}