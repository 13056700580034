import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { PropTypes } from 'prop-types'

const styles = (theme) => ({
  primary: {
    color: '#FFFFFF',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    border: '2px solid transparent',
    '&:hover': {
      border: '2px solid #ff005b',
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  secondary: {
    boxSizing: 'border-box',
    borderRadius: 20,
  },
  dark: {
    backgroundColor: '#191D24',
    color: '#fff',
  },
  pinkBorder: {
    border: '2px solid #FB005B',
  },
  round: {
    borderRadius: 30,
    padding: '5px 24px',
  }
})

const StyledButton = (props) => {
  const { 
    variant = 'primary', 
    onClick, 
    children,
    type,
    style,
    classes
  } = props
  
  let className = variant

  switch(variant) {
    case 'primary': className = variant; break
    case 'primary--round': className = classNames(classes.primary, classes.round); break
    case 'secondary': className = classes.secondary; break
    case 'secondary--round': className = classNames(classes.secondary, classes.round); break
    case 'secondary--round--pinkBorder': className = classNames(classes.secondary, classes.round, classes.pinkBorder); break
    case 'dark': className = classes.dark; break
    case 'dark--round': className = classNames(classes.dark, classes.round); break
  }

  return (
    <Button
      className={className}
      onClick={onClick && onClick}
      type={type && type}
      style={style && style}
    >
      {children}
    </Button>
  )
}

StyledButton.defaultProps = {
  classes: {},
  variant: 'primary',
  type: 'submit',
  style: {},
  children: null,
  onClick: () => {}
}
StyledButton.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  type: PropTypes.string,
  style: PropTypes.object,
}

export default withStyles(styles)(StyledButton)