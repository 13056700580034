import { combineReducers } from "redux"

import user from "./user"
import avatar from "./avatar"
import banner from "./banner"
import tournaments from "./tournaments"
import streaming from "./streaming"
import stories from "./stories"

const rootReducer = combineReducers({
  user,
  avatar,
  banner,
  tournaments,
  streaming,
  stories,
})

export default rootReducer