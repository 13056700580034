import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Grid,
  Button,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  FormHelperText
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Formik } from 'formik'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withSnackbar } from 'notistack'
import { SectionTitle2 } from 'components'
import ShowIcon from 'assets/icons/Form/Form-Password-Unhide-Red.svg'
import HideIcon from 'assets/icons/Form/Form-Password-Hide-Red.svg'
import { faLockAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { PropTypes } from 'prop-types'

/*eslint-disable */
const oneDigit = /(?=.*\d)/
const oneUpperCase = /(?=.*[A-Z])/
const minLength = /^.{8,}$/
/*eslint-enable */

const styles = (theme) => ({
  formInput: {
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 500,
    width: 280,
    height: 40,

    '& label': {
      opacity: 0.4,
      color: '#FFFFFF',
    },
    '& input': {
      // override for password filler styles
      background: 'none !important',
      color: 'inherit !important',
    },
  },
  signinTopMrgn: {
    marginTop: theme.spacing(14),
  },
  maxWidth: {
    width: '100%',
    maxWidth: 1920,
  },
  stepInputIcon: {
    color: '#ff005b',
  },
  signinButtonWrap: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  signinButton: {
    textAlign: 'center',
    textTransform: 'uppercase',
    width: 130,
    borderRadius: 500,
  },
  paddedHor: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paddedVert: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  innerStdPadded: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
})

class ForgotConfirm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      action: '/apiv2/user/forgot/',
      forgotuid: props.match.params.forgotuid,
      codeuid: props.match.params.codeuid,
      passwordType: 'password',
    }
    
    this.handleSubmit = this.handleSubmit.bind(this)
    this.togglePasswd = this.togglePasswd.bind(this)
  }

  togglePasswd() {
    let newPasswordType = 'password'
    const { passwordType } = this.state
    if (passwordType === 'password') {
      newPasswordType = 'text'
    }
    this.setState({ passwordType: newPasswordType })
  }

  validateInputs(values) {
    let errors = {}
    if (!values.password) {
      errors.password = 'Required'
    }
    if (!values.passwordconfirm) {
      errors.password = 'Required'
    }
    if (values.password !== values.passwordconfirm) {
      errors.passwordconfirm = 'Passwords must match'
    }
    if (!oneDigit.test(values.password) && values.password) {
      errors.password = 'Must contain atleast one digit'
    }
    if (!oneUpperCase.test(values.password) && values.password) {
      errors.password = 'Must contain atleast one uppercase character'
    }
    if (!minLength.test(values.password) && values.password) {
      errors.password = 'Must contain atleast 8 characters'
    }

    return errors
  }

  handleSubmit(values, props) {
    const { action, forgotuid, codeuid } = this.state
    const { setSubmitting } = props
    const { enqueueSnackbar, intl } = this.props
    const { password, passwordconfirm } = values
    event.preventDefault()
    const { formatMessage } = intl
    const fullURL = `${action}${forgotuid}/${codeuid}/`
    console.log(':: fullURL', fullURL)
    setSubmitting(true)
    window
      .fetch(fullURL, {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          passwordconfirm: passwordconfirm,
        }),
      })
      .then((response) => {
        console.log('response', response)
        if (response.status === 304) {
          return true
        } else if (response.status !== 200) {
          console.error('Non 200 or 304 response')
        }
        return response.json()
      })
      .then((data) => {
        console.error(data.error)
        setSubmitting(false)
        if (data.state) {
          enqueueSnackbar(
            formatMessage({
              id: 'forgotconfirm.form.success',
              defaultMessage: 'Password Reset.',
            }),
            { variant: 'success' }
          )
        }
      })
  }

  render() {
    const { passwordType } = this.state
    const { classes, intl } = this.props
    const { formatMessage } = intl
    return (
      <div
        className={classes.paddedVert}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Grid
          container
          justify='center'
          className={classes.paddedHor}
        >
          <Grid
            item
            xs={12}
            className={classNames(classes.signinTopMrgn, classes.maxWidth)}
          >
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <SectionTitle2 back='Reset'>Password</SectionTitle2>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.innerStdPadded}
                  >
                    <Typography
                      component='h5'
                      variant='h5'
                      align='center'
                      style={{
                        paddingBottom: '40px',
                      }}
                    >
                      Confirm Forgot Request and set Password
                    </Typography>

                    <Formik
                      initialValues={{ password: '', passwordconfirm: '' }}
                      validate={(values) => this.validateInputs(values)}
                      onSubmit={(values, { setSubmitting }) => {
                        this.handleSubmit(values, { setSubmitting })
                      }}
                      render={(props) => {
                        const {
                          values,
                          errors,
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          touched,
                        } = props

                        return (
                          <form 
                            onSubmit={handleSubmit}
                          >
                            <Grid
                              container
                              justify='center'
                              spacing={2}
                            >
                              <Grid
                                item
                                xs={12}
                              >
                                <TextField
                                  id='forgot-password'
                                  name='password'
                                  value={values.password}
                                  type={passwordType}
                                  fullWidth
                                  margin='normal'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.password ? true : false}
                                  variant='outlined'
                                  className={classes.formInput}
                                  style={{
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                  }}
                                  autoComplete='password'
                                  placeholder={formatMessage({
                                    id: 'form.label.password',
                                    defaultMessage: 'Password *',
                                  })}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        <FontAwesomeIcon
                                          icon={faLockAlt}
                                          size='lg'
                                          className={classes.stepInputIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <IconButton onClick={this.togglePasswd}>
                                        {passwordType === 'password' ? (
                                          <ShowIcon />
                                        ) : (
                                          <HideIcon />
                                        )}
                                      </IconButton>
                                    ),
                                  }}
                                />
                                <Grid
                                  item
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: 25,
                                    marginTop: 14,
                                    marginBottom: -15
                                  }}
                                >
                                  { errors.password && touched.password && <FormHelperText>{errors.password}</FormHelperText> }
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                              >
                                <TextField
                                  id='forgot-passwordconfirm'
                                  name='passwordconfirm'
                                  value={values.passwordconfirm}
                                  type={passwordType}
                                  fullWidth
                                  margin='normal'
                                  style={{
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                  }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.passwordconfirm ? true : false}
                                  autoComplete='password'
                                  variant='outlined'
                                  className={classes.formInput}
                                  placeholder={formatMessage({
                                    id: 'form.label.passwordconfirm',
                                    defaultMessage: 'Confirm Password *',
                                  })}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        <FontAwesomeIcon
                                          icon={faLockAlt}
                                          size='lg'
                                          className={classes.stepInputIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <IconButton onClick={this.togglePasswd}>
                                        {passwordType === 'password' ? (
                                          <ShowIcon />
                                        ) : (
                                          <HideIcon />
                                        )}
                                      </IconButton>
                                    ),
                                  }}
                                />
                                <Grid
                                  item
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: 25,
                                    marginTop: 14,
                                    marginBottom: -15
                                  }}
                                >
                                  { errors.passwordconfirm && touched.passwordconfirm && <FormHelperText>{errors.passwordconfirm}</FormHelperText> }
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={classes.signinButtonWrap}
                              >
                                <Button
                                  variant='contained'
                                  color='secondary'
                                  type='submit'
                                  className={classes.signinButton}
                                  style={{
                                    width: 220,
                                  }}
                                >
                                  <FormattedMessage
                                    id='forgotconfirm.form.updatepassword'
                                    defaultMessage='UPDATE PASSWORD'
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

ForgotConfirm.defaultProps = {
  match: {},
  classes: {},
  enqueueSnackbar: () => {},
  intl: {},
}

ForgotConfirm.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  intl: PropTypes.object,
}

export default withStyles(styles)(
  injectIntl(withRouter(withSnackbar(ForgotConfirm)))
)
