// # IMPORT APPLICATION CONSTANTS
// import * as constant from 'constants'
import {
  STORIES_GET_STARTED,
  STORIES_GET_SUCCESS,
  STORIES_GET_FAILED,
} from 'constants'

const initialState = {
  data: null,
  filteredData: null,
  loaded: false,
}

export default function stories(state = initialState, action) {
  switch (action.type) {

    case STORIES_GET_STARTED:
      return {
        ...state,
      }

    case STORIES_GET_SUCCESS:
      return{
        ...state,
        data: action.payload,
        loaded: true,
      }

    case STORIES_GET_FAILED:
      return {
        ...state,
        loaded: false
      }

    default:
      return state

  }
}
