import React, { Suspense, lazy, useEffect, StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import PrivateRoute from 'components/PrivateRoute'
import UserProvider from 'components/UserContext'
import InitSW from 'components/InitSW'
import Analytics from 'react-router-ga'
import {
  Header,
  Footer,
} from 'containers'
import { ScrollToTop } from 'components'
import ForgotConfirm from 'containers/ForgotConfirm'
// import Forgot from 'containers/Forgot'
// import PurchasePage from 'containers/Purchase'
import OkIcon from 'assets/icons/Form/Form-Message-Alert-Check.svg'
import NokIcon from 'assets/icons/Form/Form-Message-Error-Red.svg'
import WarnIcon from 'assets/icons/Form/Form-Message-Warning.svg'
import InfoIcon from 'assets/icons/Form/Form-Message-Alert-Warning-Orange.svg'
import theme from 'Theme'
import configureStore from './store/index'
import { version } from '../package.json'

const Home = lazy(() =>
  import(/* webpackChunkName: 'home' */ 'containers/Home')
)
const Profile = lazy(() =>
  import(/* webpackChunkName: 'profile' */ 'containers/Profile')
)
const Settings = lazy(() =>
  import(/* webpackChunkName: 'settings' */ 'containers/Settings')
)
const SignOut = lazy(() =>
  import(/* webpackChunkName: 'signout' */ 'containers/SignOut')
)
const Story = lazy(() =>
  import(/* webpackChunkName: 'story' */ 'containers/Story')
)
const Music = lazy(() =>
  import(/* webpackChunkName: 'music' */ 'containers/Music')
)
const Channels = lazy(() =>
  import(/* webpackChunkName: 'channels' */ 'containers/Channels')
)
const ESports = lazy(() =>
  import(/* webpackChunkName: 'esports' */ 'containers/ESports')
)
const Streaming = lazy(() =>
  import(/* webpackChunkName: 'streaming' */ 'containers/Streaming')
)
const FAQ = lazy(() => import(/* webpackChunkName: 'faq' */ 'containers/FAQ'))
const About = lazy(() =>
  import(/* webpackChunkName: 'about' */ 'containers/About')
)
const TermsAndConditions = lazy(() =>
  import(/* webpackChunkName: 'terms' */ 'containers/TermsAndConditions')
)
const BecomeAPartner = lazy(() =>
  import(/* webpackChunkName: 'becomeapartner' */ 'containers/BecomeAPartner')
)
const PrivacyPolicy = lazy(() =>
  import(/* webpackChunkName: 'privacy' */ 'containers/PrivacyPolicy')
)
const News = lazy(() =>
  import(/* webpackChunkName: 'news' */ 'containers/News')
)
const Tournament = lazy(() =>
  import(/* webpackChunkName: 'tournament' */ 'containers/Tournament')
)
const Contact = lazy(() =>
  import(/* webpackChunkName: 'contact' */ 'containers/Contact')
)
const SignUp = lazy(() =>
  import(/* webpackChunkName: 'signup' */ 'containers/SignUp')
)
const SignIn = lazy(() =>
  import(/* webpackChunkName: 'signin' */ 'containers/SignIn')
)
const ErrorPage = lazy(() =>
  import(/* webpackChunkName: '404' */ 'containers/ErrorPage')
)
const Forgot = lazy(() =>
  import(/* webpackChunkName: 'forgot' */ 'containers/Forgot')
)
const ActivateAccount = lazy(() =>
  import(/* webpackChunkName: 'activate' */ 'containers/ActivateAccount')
)

//const CreditCard = lazy(() =>
//  import(/* webpackChunkName: 'private' */ 'containers/CreditCard')
//)
//const Transactions = lazy(() =>
//  import(/* webpackChunkName: 'private' */ 'containers/Transactions')
//)
//const InvoicePage = lazy(() =>
//  import(/* webpackChunkName: 'private' */ 'containers/Invoice')
//)

const useStyles = makeStyles((theme) => ({
  snakSlate: {
    padding: 0,
    backgroundColor: '#FFF',
    borderRadius: 0,
    color: '#000',
    '& svg': {
      height: 72,
      width: 72,
      padding: theme.spacing(3),
      marginRight: theme.spacing(2),
      borderRight: '#999 solid 1px',
    },
  },
  fallbackContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  mainContent: {
    flex: 2,
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: 50,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: 75,
    },
  },
  fallbackProgressContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  fallbackProgressText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  progress: {
    margin: theme.spacing(2),
  },
}))

const App = () => {
  const localStyles = useStyles()
  const store = configureStore()

  useEffect(() => {
    if (version !== localStorage.getItem('version')) {
      console.warn('Version Updated')
      // clear cache
      caches.keys().then(function(names) {
        for (let name of names)
          caches.delete(name)
      })
      localStorage.setItem('version', version)
    }
    console.warn(version)
  })

  return (
    <Provider store={store}>
      <UserProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={3}
              iconVariant={{
                success: <OkIcon />,
                error: <NokIcon />,
                warning: <WarnIcon />,
                info: <InfoIcon />,
              }}
              classes={{
                variantSuccess: localStyles.snakSlate,
                variantError: localStyles.snakSlate,
              }}
            >
              {/* hide header on home page */}
              <div className={localStyles.mainContent}>
                <Suspense
                  fallback={(
                    <div className={localStyles.fallbackContainer}>
                      <div className={localStyles.fallbackProgressContainer}>
                        <CircularProgress
                          className={localStyles.progress}
                          variant="indeterminate"
                        />
                        <Typography className={localStyles.fallbackProgressText}>
                          Loading
                        </Typography>
                      </div>
                    </div>
                  )}
                >
                  <Analytics
                    id='UA-145178364-1'
                    debug
                    trackPathnameOnly
                  >
                    <StrictMode>
                      <Header />
                      <ScrollToTop>
                        <Switch>
                          <Route
                            exact
                            path='/'
                            component={Home}
                          />
                          <Route
                            exact
                            path='/sign-in'
                            component={SignIn}
                          />
                          <Route
                            exact
                            path='/sign-up'
                            component={SignUp}
                          />
                          <PrivateRoute
                            exact
                            path='/sign-out'
                            component={SignOut}
                          />
                          <Route
                            exact
                            path='/forgot'
                            component={Forgot}
                          />
                          <Route
                            exact
                            path='/forgot/:forgotuid/:codeuid'
                            component={ForgotConfirm}
                          />
                          <Route
                            exact
                            path='/activate/:useruid/:codeuid'
                            component={ActivateAccount}
                          />
                          <Route
                            exact
                            path='/esports'
                            component={ESports}
                          />
                          <Route
                            exact
                            path='/esports/tournaments'
                            component={Tournament}
                          />
                          <Route
                            exact
                            path='/esports/tournaments/fortnite-open'
                            component={Tournament}
                          />
                          <Route
                            path='/music'
                            exact
                            component={Music}
                          />
                          <Route
                            path='/channels'
                            exact
                            component={Channels}
                          />
                          <Route
                            path='/streaming'
                            exact
                            component={Streaming}
                          />
                          <Route
                            exact
                            path='/news'
                            component={News}
                          />
                          <Route
                            path='/news/:endpointTag/:endpointType?'
                            exact
                            component={News}
                          />
                          <Route
                            path='/story/:suuid'
                            exact
                            component={Story}
                          />
                          <Route
                            path='/faq'
                            exact
                            component={FAQ}
                          />
                          <Route
                            path='/about'
                            exact
                            component={About}
                          />
                          <Route
                            path='/terms'
                            exact
                            component={TermsAndConditions}
                          />
                          <Route
                            path='/privacypolicy'
                            exact
                            component={PrivacyPolicy}
                          />
                          <Route
                            path='/partner'
                            exact
                            component={BecomeAPartner}
                          />
                          <Route
                            path='/contact'
                            exact
                            component={Contact}
                          />
                          <PrivateRoute
                            path='/profile'
                            exact
                            component={Profile}
                          />
                          <PrivateRoute
                            path='/settings'
                            exact
                            component={Settings}
                          />

                          <Route
                            path="*"
                            component={ErrorPage}
                          />
                          {/* <Route
                            path='/purchase/:euuid'
                            component={PurchasePage}
                          /> */}
                          {/* <PrivateRoute path='/cards' component={CreditCard} /> */}
                          {/* <PrivateRoute
                            path='/transactions'
                            component={Transactions}
                          /> */}
                          {/* <PrivateRoute
                            path='/invoice/:iuuid'
                            component={InvoicePage}
                          /> */}
                        </Switch>
                      </ScrollToTop>
                      <Footer />
                    </StrictMode>
                  </Analytics>
                </Suspense>
              </div>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </UserProvider>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))

InitSW()
